package com.sludg.util.models

import java.time.LocalDateTime

import com.sludg.util.models.CallModels.TerminationPoint.objToStr
import com.sludg.util.models.GroupingModels.{RingStats, TalkStats}

import cats.Eq
import cats.Show

object CallModels {

  case class CDR(
      answer: Boolean,
      callId: String,
      callingNumber: Option[String],
      cos: ClassOfService,
      dialedNumber: Option[String],
      direction: Direction,
      duration: Long,
      ringTime: Long,
      site: Option[String],
      talkTime: Option[Long],
      tenantId: Long,
      timestamp: LocalDateTime,
      terminationPoint: TerminationPoint,
      subscriberIds: Option[List[Int]],
      subscriberExtensions: Option[List[String]],
      autoAttendantExtensions: Option[List[String]],
      callGroupExtensions: Option[List[String]]
  )

  object CDR {
    implicit val eq = Eq.fromUniversalEquals[CDR]
    implicit val show = Show.fromToString[CDR]
  }

  case class CDRSegment(
      callId: String,
      tenantId: Long,
      description: Option[String],
      timestamp: LocalDateTime,
      talkTime: Long,
      ringTime: Long,
      duration: Long,
      segmentIndex: Int,
      originatorType: TerminationPoint,
      terminatorType: TerminationPoint,
      originatorSubId: Option[Int],
      terminatorSubId: Option[Int],
      originatorExtension: Option[String],
      terminatorExtension: Option[String],
      terminatorVoicemail: Boolean
  )

  object CDRSegment {
    implicit val eq = Eq.fromUniversalEquals[CDRSegment]
    implicit val show = Show.fromToString[CDRSegment]
  }

  case class CallStats(
      totalsCalls: Int,
      answered: Int,
      notAnswered: Int,
      talkStats: TalkStats,
      ringStats: RingStats
  )

  object CallStats {
    implicit val eq = Eq.fromUniversalEquals[CallStats]
    implicit val show = Show.fromToString[CallStats]
  }

  sealed trait Direction

  object Direction {
    implicit val eq = Eq.fromUniversalEquals[Direction]
    implicit val show = Show.fromToString[Direction]
    case object Inbound extends Direction
    case object Outbound extends Direction
    case object Internal extends Direction

    val all: List[Direction] = List(Inbound, Outbound, Internal)

    val strToObj: Map[String, Direction] = Map(
      "Inbound" -> Inbound,
      "Outbound" -> Outbound,
      "Internal" -> Internal
    )

    val objToStr: Map[Direction, String] = strToObj.map(_.swap)

    implicit val ordering: Ordering[Direction] = Ordering.by(objToStr)
  }

  sealed trait ClassOfService

  object ClassOfService {
    implicit val eq = Eq.fromUniversalEquals[ClassOfService]
    implicit val show = Show.fromToString[ClassOfService]
    case object Premium extends ClassOfService
    case object OperatorAssisted extends ClassOfService
    case object Mobile extends ClassOfService
    case object Emergency extends ClassOfService
    case object International extends ClassOfService
    case object National extends ClassOfService
    case object None extends ClassOfService
    case object Freephone extends ClassOfService

    val all: List[ClassOfService] = List(
      Premium,
      OperatorAssisted,
      Mobile,
      Emergency,
      International,
      National,
      None,
      Freephone
    )

    val strToObj: Map[String, ClassOfService] = Map(
      "Premium" -> Premium,
      "OperatorAssisted" -> OperatorAssisted,
      "Mobile" -> Mobile,
      "Emergency" -> Emergency,
      "International" -> International,
      "National" -> National,
      "None" -> None,
      "Freephone" -> Freephone
    )

    val objToStr: Map[ClassOfService, String] = strToObj.map(_.swap)

    implicit val ordering: Ordering[ClassOfService] = Ordering.by(objToStr)

  }

  sealed trait TerminationPoint

  object TerminationPoint {
    implicit val eq = Eq.fromUniversalEquals[TerminationPoint]
    implicit val show = Show.fromToString[TerminationPoint]
    case object Human extends TerminationPoint
    case object AutoAttendant extends TerminationPoint
    case object Voicemail extends TerminationPoint
    case object CallGroup extends TerminationPoint

    val all: List[TerminationPoint] =
      List(Human, AutoAttendant, Voicemail, CallGroup)

    val strToObj: Map[String, TerminationPoint] = Map(
      "Human" -> Human,
      "AutoAttendant" -> AutoAttendant,
      "Voicemail" -> Voicemail,
      "CallGroup" -> CallGroup
    )

    val objToStr: Map[TerminationPoint, String] = strToObj.map(_.swap)

    implicit val ordering: Ordering[TerminationPoint] = Ordering.by(objToStr)

  }

}
