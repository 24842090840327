package com.sludg.util.models

import java.time.temporal.WeekFields
import java.time.{LocalDate, LocalDateTime}
import java.util.Locale
import cats.Eq
import cats.Show

import com.sludg.util.models.CallModels.{CDR, ClassOfService, TerminationPoint}

object GroupingModels {

  case class CategoryData[A](category: Category[A], data: A)

  object CategoryData {
    implicit def eq[A] = Eq.fromUniversalEquals[CategoryData[A]]
    implicit def show[A] = Show.fromToString[CategoryData[A]]
  }

  sealed trait Stats {
    val sum: Long
    val min: Option[Long]
    val max: Option[Long]
    val average: Option[Double]
  }

  object Stats {
    implicit val eq = Eq.fromUniversalEquals[Stats]
    implicit val show = Show.fromToString[Stats]
  }

  case class TotalDurationStats(
      sum: Long,
      min: Option[Long],
      max: Option[Long],
      average: Option[Double]
  ) extends Stats

  object TotalDurationStats {
    implicit val eq = Eq.fromUniversalEquals[TotalDurationStats]
    implicit val show = Show.fromToString[TotalDurationStats]
  }

  case class TalkStats(
      sum: Long,
      min: Option[Long],
      max: Option[Long],
      average: Option[Double]
  ) extends Stats

  object TalkStats {
    implicit val eq = Eq.fromUniversalEquals[TalkStats]
    implicit val show = Show.fromToString[TalkStats]
  }

  case class RingStats(
      sum: Long,
      min: Option[Long],
      max: Option[Long],
      average: Option[Double]
  ) extends Stats

  object RingStats {
    implicit val eq = Eq.fromUniversalEquals[RingStats]
    implicit val show = Show.fromToString[RingStats]
  }

  trait ReportData {
    val total: Int
    val totalStats: TotalDurationStats
    val talkStats: TalkStats
    val ringStats: RingStats
    val subGroups: List[ChildReportData]
  }

  object ReportData {
    implicit val eq = Eq.fromUniversalEquals[ReportData]
    implicit val show = Show.fromToString[ReportData]
  }

  case class ChildReportData(
      categoryData: CategoryData[_],
      total: Int,
      totalStats: TotalDurationStats,
      talkStats: TalkStats,
      ringStats: RingStats,
      subGroups: List[ChildReportData]
  ) extends ReportData

  object ChildReportData {
    implicit val eq = Eq.fromUniversalEquals[ChildReportData]
    implicit val show = Show.fromToString[ChildReportData]
  }

  case class RootReportData(
      total: Int,
      totalStats: TotalDurationStats,
      talkStats: TalkStats,
      ringStats: RingStats,
      subGroups: List[ChildReportData]
  ) extends ReportData

  object RootReportData {
    implicit val eq = Eq.fromUniversalEquals[RootReportData]
    implicit val show = Show.fromToString[RootReportData]
  }

  sealed trait Category[A] {
    def ordering: Ordering[CDR]
  }

  object Category {
    implicit def eq[A] = Eq.fromUniversalEquals[Category[A]]
    implicit def show[A] = Show.fromToString[Category[A]]

    import Ordering.Implicits._

    case object Direction extends Category[CallModels.Direction] {
      override def ordering: Ordering[CDR] = Ordering.by(_.direction)
    }

    case object HourOfDay extends Category[Int] {
      override def ordering: Ordering[CDR] = Ordering.by(_.timestamp.getHour)
    }

    case object Day extends Category[LocalDate] {
      implicit val localDateTimeOrdering: Ordering[LocalDateTime] =
        (o1: LocalDateTime, o2: LocalDateTime) => o1.compareTo(o2)
      override def ordering: Ordering[CDR] = Ordering.by(_.timestamp)
    }

    case object DayOfWeek extends Category[Int] {
      override def ordering: Ordering[CDR] =
        Ordering.by(_.timestamp.getDayOfWeek)
    }

    case object Week extends Category[Int] {
      private val weekField =
        WeekFields.of(Locale.getDefault()).weekOfWeekBasedYear()
      override def ordering: Ordering[CDR] =
        Ordering.by(_.timestamp.get(weekField))
    }

    case object Month extends Category[Int] {
      override def ordering: Ordering[CDR] =
        Ordering.by(_.timestamp.getMonth.getValue)
    }

    case object Subscriber extends Category[Option[Int]] {
      override def ordering: Ordering[CDR] = Ordering.by(_.subscriberIds)
    }

    case object Answer extends Category[Boolean] {
      override def ordering: Ordering[CDR] = Ordering.by(_.answer)
    }

    case object LastExtension extends Category[Option[String]] {
      override def ordering: Ordering[CDR] =
        Ordering.by(_.subscriberExtensions.flatMap(_.lastOption))
    }

    case object ClassOfService extends Category[ClassOfService] {
      override def ordering: Ordering[CDR] =
        Ordering.by[CDR, CallModels.ClassOfService](_.cos)(
          CallModels.ClassOfService.ordering
        )
    }

    case object Termination extends Category[TerminationPoint] {
      override def ordering: Ordering[CDR] =
        Ordering.by[CDR, CallModels.TerminationPoint](_.terminationPoint)(
          CallModels.TerminationPoint.ordering
        )
    }

    case object AutoAttendant extends Category[Option[String]] {
      override def ordering: Ordering[CDR] =
        Ordering.by(_.autoAttendantExtensions)
    }

    case object CallGroup extends Category[Option[String]] {
      override def ordering: Ordering[CDR] = Ordering.by(_.callGroupExtensions)
    }

    case object DialledNumber extends Category[Option[String]] {
      override def ordering: Ordering[CDR] = Ordering.by(_.dialedNumber)
    }

    val strToObj: Map[String, Category[_]] = Map(
      "Direction" -> Direction,
      "HourOfDay" -> HourOfDay,
      "Day" -> Day,
      "DayOfWeek" -> DayOfWeek,
      "Week" -> Week,
      "Month" -> Month,
      "Subscriber" -> Subscriber,
      "Answer" -> Answer,
      "LastExtension" -> LastExtension,
      "AutoAttendant" -> AutoAttendant,
      "CallGroup" -> CallGroup,
      "ClassOfService" -> ClassOfService,
      "Termination" -> Termination,
      "DialledNumber" -> DialledNumber
    )
  }

}
