package com.sludg.util.json

import java.time.LocalDate

import com.sludg.util.JsonUtils
import com.sludg.util.models.CallModels
import com.sludg.util.models.GroupingModels._
import play.api.libs.functional.syntax._
import play.api.libs.json._

object GroupingJsonDeserializers {

  import CallJsonDeserializers._
  import com.sludg.util.JavaTimeFormats._

  implicit lazy val totalDurationStatsFormat: Format[TotalDurationStats] =
    Json.format[TotalDurationStats]
  implicit lazy val talkStatsFormat: Format[TalkStats] = Json.format[TalkStats]
  implicit lazy val ringStatsFormat: Format[RingStats] = Json.format[RingStats]

  implicit lazy val categoryFormat: Format[Category[_]] =
    JsonUtils.formatViaMap(Category.strToObj)

  implicit lazy val categoryDataReads: Reads[CategoryData[_]] = {
    def readData[A: Reads](cat: Category[A]): Reads[CategoryData[_]] =
      (JsPath \ "data").read[A].map(v => CategoryData[A](cat, v))

    def readNullableData[A: Reads](
        cat: Category[Option[A]]
    ): Reads[CategoryData[_]] =
      (JsPath \ "data")
        .readNullable[A]
        .map(v => CategoryData[Option[A]](cat, v))

    (JsPath \ "category").read[Category[_]].flatMap {
      case x @ Category.Direction      => readData(x)
      case x @ Category.HourOfDay      => readData(x)
      case x @ Category.Day            => readData(x)
      case x @ Category.DayOfWeek      => readData(x)
      case x @ Category.Week           => readData(x)
      case x @ Category.Month          => readData(x)
      case x @ Category.Subscriber     => readNullableData(x)
      case x @ Category.Answer         => readData(x)
      case x @ Category.LastExtension  => readNullableData(x)
      case x @ Category.AutoAttendant  => readNullableData(x)
      case x @ Category.CallGroup      => readNullableData(x)
      case x @ Category.ClassOfService => readData(x)
      case x @ Category.Termination    => readData(x)
      case x @ Category.DialledNumber  => readNullableData(x)
    }
  }

  implicit lazy val categoryDataWrites: Writes[CategoryData[_]] =
    ((JsPath \ "category")
      .write[Category[_]] and (JsPath \ "data").writeNullable[Any](Writes[Any] {
      case x: Boolean   => JsBoolean(x)
      case x: Int       => JsNumber(x)
      case x: LocalDate => implicitly[Writes[LocalDate]].writes(x)
      case x: String    => JsString(x)
      case x: CallModels.ClassOfService =>
        implicitly[Writes[CallModels.ClassOfService]].writes(x)
      case x: CallModels.TerminationPoint =>
        implicitly[Writes[CallModels.TerminationPoint]].writes(x)
      case x: CallModels.Direction =>
        implicitly[Writes[CallModels.Direction]].writes(x)
      case _ => JsNull
    }))((c: CategoryData[_]) =>
      c match {
        case CategoryData(category, data: Option[_]) => category -> data
        case CategoryData(category, data)            => category -> Some(data)
      }
    )

  implicit lazy val rootReportDataReads: Reads[RootReportData] =
    reportDataReads(RootReportData.apply _)
  implicit lazy val rootReportDataWrites: Writes[RootReportData] =
    reportDataWrites(unlift(RootReportData.unapply))

  implicit lazy val childReportDataReads: Reads[ChildReportData] =
    (reportDataReads and (JsPath \ "categoryData").read[CategoryData[_]])(
      (a, b, c, d, e, f) => ChildReportData(f, a, b, c, d, e)
    )
  implicit lazy val childReportDataWrites: Writes[ChildReportData] =
    (reportDataWrites and (JsPath \ "categoryData").write[CategoryData[_]]) {
      case ChildReportData(a, b, c, d, e, f) => (b, c, d, e, f, a)
    }

  lazy val reportDataReads = (JsPath \ "total").read[Int] and
    (JsPath \ "totalDurationStats").read[TotalDurationStats] and
    (JsPath \ "talkStats").read[TalkStats] and
    (JsPath \ "ringStats").read[RingStats] and
    (JsPath \ "subGroups")
      .lazyRead[List[ChildReportData]](implicitly[Reads[List[ChildReportData]]])

  lazy val reportDataWrites = (JsPath \ "total").write[Int] and
    (JsPath \ "totalDurationStats").write[TotalDurationStats] and
    (JsPath \ "talkStats").write[TalkStats] and
    (JsPath \ "ringStats").write[RingStats] and
    (JsPath \ "subGroups").lazyWrite[List[ChildReportData]](
      implicitly[Writes[List[ChildReportData]]]
    )

}
