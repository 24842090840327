package com.sludg.util.json

import com.sludg.util.models.DashboardComponentModels.CallGroupMember
import com.sludg.util.models.SilhouetteModels
import com.sludg.util.models.SilhouetteModels._
import play.api.libs.functional.syntax._
import play.api.libs.json._
import play.api.libs.json.Reads._
import play.api.libs.json.Writes._

/**
  * @author dpoliakas
  *         Date: 19/11/2018
  *         Time: 13:04
  */
object SilhouetteDeserializers {

  implicit val subscriberReads: Reads[Subscriber] = (
    (JsPath \ "tenantId").read[Int] and
      (JsPath \ "extension").read[String] and
      (JsPath \ "subscriberId").read[Int] and
      (JsPath \ "firstName").readNullable[String] and
      (JsPath \ "lastName").read[String] and
      (JsPath \ "email").read[String] and
      (JsPath \ "admin").read[Boolean]
  )(Subscriber.apply _)

  implicit val subscriberWrites: Writes[Subscriber] = (
    (JsPath \ "tenantId").write[Int] and
      (JsPath \ "extension").write[String] and
      (JsPath \ "subscriberId").write[Int] and
      (JsPath \ "firstName").writeNullable[String] and
      (JsPath \ "lastName").write[String] and
      (JsPath \ "email").write[String] and
      (JsPath \ "admin").write[Boolean]
  )(unlift(Subscriber.unapply))

  implicit val cgmdr: Reads[CallGroupMemberData] =
    Json.reads[CallGroupMemberData]
  implicit val cgmdw: Writes[CallGroupMemberData] =
    Json.writes[CallGroupMemberData]

  implicit val cgmr: Reads[CallGroupMember] = Json.reads[CallGroupMember]
  implicit val cgmw: Writes[CallGroupMember] = Json.writes[CallGroupMember]

  implicit val crr: Reads[CallResponse] = Json.reads[CallResponse]
  implicit val crw: Writes[CallResponse] = Json.writes[CallResponse]

  implicit val permObj: Reads[PermissionObj] = (
    (JsPath \ "pname").read[String] and
      (JsPath \ "tenantId").readNullable[Int] and
      (JsPath \ "subscriberId").readNullable[Int]
  )(PermissionObj.apply _)

  implicit val permObjWrites: Writes[PermissionObj] = (
    (JsPath \ "pname").write[String] and
      (JsPath \ "tenantId").writeNullable[Int] and
      (JsPath \ "subscriberId").writeNullable[Int]
  )(unlift(PermissionObj.unapply))

  implicit val tenantReads: Reads[Tenant] = (
    (JsPath \ "tid").read[Int] and
      (JsPath \ "tname").read[String] and
      (JsPath \ "turi").read[String]
  )(Tenant.apply _)

  implicit val tenantWrites: Writes[Tenant] = (
    (JsPath \ "tid").write[Int] and
      (JsPath \ "tname").write[String] and
      (JsPath \ "turi").write[String]
  )(unlift(Tenant.unapply))

  implicit val carrierObjectReads: Reads[CarrierObject] = (
    (JsPath \ "permissionName").read[String] and
      (JsPath \ "permissionType").read[String]
  )(CarrierObject.apply _)

  implicit val businessHoursReads: Reads[BusinessHoursProfile] = (
    (JsPath \ "businessHoursProfileId").read[Int] and
      (JsPath \ "businessHoursProfileName").readNullable[String]
  )(BusinessHoursProfile.apply _)

  implicit val emergencyOverrideReads: Reads[EmergencyOverride] = (
    (JsPath \ "active").read[Boolean] and
      (JsPath \ "name").readNullable[String] and
      (JsPath \ "number").readNullable[String]
  )(EmergencyOverride.apply _)

  implicit val didNumberReads: Reads[DIDNumber] = Json.reads[DIDNumber]

  implicit val businessHoursWrites: Writes[BusinessHoursProfile] = (
    (JsPath \ "businessHoursProfileId").write[Int] and
      (JsPath \ "businessHoursProfileName").writeNullable[String]
  )(unlift(BusinessHoursProfile.unapply))

  implicit val emergencyOverrideWrites: Writes[EmergencyOverride] = (
    (JsPath \ "active").write[Boolean] and
      (JsPath \ "name").writeNullable[String] and
      (JsPath \ "number").writeNullable[String]
  )(unlift(EmergencyOverride.unapply))

  implicit val didNumberWrites: Writes[DIDNumber] = Json.writes[DIDNumber]

  implicit val hourlyExtensionsFormat: Format[HourlyExtensions] =
    Json.format[HourlyExtensions]

  implicit val autoAttendantFormat: Format[AutoAttendant] =
    Json.format[AutoAttendant]

  implicit val userPhoneReads: Reads[UserPhone] = (
    (JsPath \ "identifier").read[String] and
      (JsPath \ "phoneId").read[Int] and
      (JsPath \ "phoneModelName").read[String] and
      (JsPath \ "userLabel").read[String] and
      (JsPath \ "defaultC2CPhone").read[Boolean] and
      (JsPath \ "defaultIntercomPhone").read[Boolean]
  )(UserPhone.apply _)

  implicit val userPhoneWrites: Writes[UserPhone] = (
    (JsPath \ "identifier").write[String] and
      (JsPath \ "phoneId").write[Int] and
      (JsPath \ "phoneModelName").write[String] and
      (JsPath \ "userLabel").write[String] and
      (JsPath \ "defaultC2CPhone").write[Boolean] and
      (JsPath \ "defaultIntercomPhone").write[Boolean]
  )(unlift(UserPhone.unapply))

}
