package com.sludg.util.json

import com.sludg.util.models.DashboardComponentModels.{
  CallGroupComponentData,
  CallGroupMember,
  ComponentType,
  DashboardComponent
}
import com.sludg.util.models.SilhouetteModels.{
  CallGroup,
  CallGroupMemberData,
  CallGroupType,
  Subscriber
}
import play.api.libs.json._
import SilhouetteDeserializers.subscriberWrites
import SilhouetteDeserializers.subscriberReads
import FiltersJsonDeserializers._

object DashboardComponentDeserializers {
  import com.sludg.util.JsonUtils._

  implicit val componentTypeFormat: Format[ComponentType] = formatViaMap(
    ComponentType.stringToObjection
  )
  implicit val dashboardComponentReads: Reads[DashboardComponent] =
    Json.reads[DashboardComponent]
  implicit val dashboardComponentWrites: Writes[DashboardComponent] =
    Json.writes[DashboardComponent]

  implicit val callGroupType: Format[CallGroupType] = formatViaMap(
    CallGroupType.strToObj
  )
  implicit val cgReads: Reads[CallGroup] = Json.reads[CallGroup]
  implicit val cgWrites: Writes[CallGroup] = Json.writes[CallGroup]

  implicit val cgmdReads: Reads[CallGroupMemberData] =
    Json.reads[CallGroupMemberData]
  implicit val cgmdWrites: Writes[CallGroupMemberData] =
    Json.writes[CallGroupMemberData]

  implicit val cgmReads: Reads[CallGroupMember] = Json.reads[CallGroupMember]
  implicit val cgmWrites: Writes[CallGroupMember] = Json.writes[CallGroupMember]

  implicit val cgdReads: Reads[CallGroupComponentData] =
    Json.reads[CallGroupComponentData]
  implicit val cgdWrites: Writes[CallGroupComponentData] =
    Json.writes[CallGroupComponentData]
}
