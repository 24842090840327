package com.sludg.util

import com.sludg.util.models.CallModels.ClassOfService.OperatorAssisted
import com.sludg.util.models.CallModels.TerminationPoint.AutoAttendant
import com.sludg.util.models.GroupingModels.Category._
import com.sludg.util.models.GroupingModels._
import com.sludg.util.models.ReportModels.ProjectionType
import com.sludg.util.models.ReportModels.ProjectionType._
import com.sludg.util.models.SilhouetteModels
import com.sludg.util.models.CallModels
import com.sludg.util.models.CallModels.TerminationPoint.CallGroup
import com.sludg.util.models.CallModels.TerminationPoint.Human
import com.sludg.util.models.CallModels.TerminationPoint.Voicemail
import com.sludg.util.models.CallModels.ClassOfService.Mobile
import com.sludg.util.models.CallModels.ClassOfService.Freephone
import com.sludg.util.models.CallModels.ClassOfService.International
import com.sludg.util.models.CallModels.ClassOfService.Premium
import com.sludg.util.models.CallModels.ClassOfService.Emergency
import com.sludg.util.models.CallModels.ClassOfService.National
import com.sludg.util.models.CallModels.ClassOfService.None

trait Presenter[-A] {
  def present(value: A): String
}

object Presenter {
  def apply[A](implicit p: Presenter[A]): Presenter[A] = p

  implicit def listPresenter[A](implicit p: Presenter[A]): Presenter[List[A]] =
    (value: List[A]) => value.map(p.present).mkString(", ")
  implicit val stringPresenter = new Presenter[String] {
    override def present(value: String): String = value
  }

  implicit val intPresetner = fromUniversalToString[Int]

  def fromUniversalToString[A]: Presenter[A] = (value: A) => value.toString()
}

object Presenters {
  def present[A](v: A)(implicit presenter: Presenter[A]) = presenter.present(v)
}

object PresenterSyntax {

  implicit class PresenterOps[A](value: A) {
    def present(implicit p: Presenter[A]): String = Presenters.present(value)
  }

}

object ApiModelPresenters {

  implicit val cosPresenter = new Presenter[CallModels.ClassOfService] {
    override def present(value: CallModels.ClassOfService): String =
      value match {
        case Mobile           => "Mobile"
        case Freephone        => "Free"
        case International    => "International"
        case Premium          => "Premium"
        case Emergency        => "Emergency"
        case National         => "National"
        case OperatorAssisted => "Operator Assisted"
        case None             => "None"
      }
  }

  implicit val terminationPointPresenter =
    new Presenter[CallModels.TerminationPoint] {
      def present(value: CallModels.TerminationPoint): String =
        value match {
          case AutoAttendant => "Auto Attendant"
          case CallGroup     => "Call Group"
          case Human         => "Human"
          case Voicemail     => "Voicemail"
        }
    }

  implicit val categoryPresenter = new Presenter[Category[_]] {
    def present(value: Category[_]): String = {
      value match {
        case Direction              => "Direction"
        case HourOfDay              => "Hour of Day"
        case Day                    => "Day"
        case DayOfWeek              => "Day of Week"
        case Week                   => "Week"
        case Month                  => "Month"
        case Subscriber             => "User"
        case Answer                 => "Answer"
        case LastExtension          => "Extension"
        case Category.AutoAttendant => "Auto Attendant"
        case Category.CallGroup     => "Call Group"
        case ClassOfService         => "Class of Service"
        case Termination            => "Termination"
        case DialledNumber          => "Dialled Number"
        case _                      => value.toString
      }
    }
  }

  implicit val projectionTypePresenter = new Presenter[ProjectionType] {
    def present(value: ProjectionType): String = {
      value match {
        case TotalCalls     => "Total Calls"
        case MinDurations   => "Min Durations"
        case MaxDurations   => "Max Durations"
        case AvgDurations   => "Avg Durations"
        case TotalDurations => "Total Durations"
        case MinTalkTime    => "Min Talk Time"
        case MaxTalkTime    => "Max Talk Time"
        case AvgTalkTime    => "Avg Talk Time"
        case TotalTalkTime  => "Total Talk Time"
        case MinRingTime    => "Min Ring Time"
        case MaxRingTime    => "Max Ring Time"
        case AvgRingTime    => "Avg Ring Time"
        case TotalRingTime  => "Total Ring Time"
        case _              => value.toString
      }
    }
  }

  implicit val categoryData = new Presenter[CategoryData[_]] {
    def present(value: CategoryData[_]): String = {
      value match {
        case CategoryData(Answer, data) =>
          data match {
            case true  => "Answered"
            case false => "Not Answered"
          }
        case CategoryData(ClassOfService, data) =>
          data match {
            case OperatorAssisted => "Operator Assisted"
            case _                => data.toString
          }
        case CategoryData(DayOfWeek, data) =>
          data match {
            case 0 => "Sunday"
            case 1 => "Monday"
            case 2 => "Tuesday"
            case 3 => "Wednesday"
            case 4 => "Thursday"
            case 5 => "Friday"
            case 6 => "Saturday"
          }
        case CategoryData(Direction, data) => data.toString
        case CategoryData(Month, data) =>
          data match {
            case 1  => "January"
            case 2  => "February"
            case 3  => "March"
            case 4  => "April"
            case 5  => "May"
            case 6  => "June"
            case 7  => "July"
            case 8  => "August"
            case 9  => "September"
            case 10 => "October"
            case 11 => "November"
            case 12 => "December"
          }
        case CategoryData(HourOfDay, data) =>
          s"${data.toString}:00"
        case CategoryData(Day, data) =>
          data.toString
        case CategoryData(Week, data) => data.toString
        case CategoryData(Subscriber, data) =>
          data match {
            case Some(sub) => sub.toString
            case None      => "No User"
          }
        case CategoryData(LastExtension, data: Option[String]) =>
          data.getOrElse("No Extension")
        case CategoryData(Category.AutoAttendant, data: Option[String]) =>
          data.getOrElse("No Auto Attendant")
        case CategoryData(Category.CallGroup, data: Option[String]) =>
          data.getOrElse("No Call Group")
        case CategoryData(Termination, data) =>
          data match {
            case AutoAttendant => "Auto Attendant"
            case _             => data.toString
          }
        case CategoryData(Category.DialledNumber, data: Option[String]) =>
          data.getOrElse("No Number")
        case CategoryData(_, data) => data.toString
      }
    }
  }

  implicit val directionPresenter =
    Presenter.fromUniversalToString[CallModels.Direction]
}

object SilhouettePresenters {

  implicit val autoAttendantPresenter =
    new Presenter[SilhouetteModels.AutoAttendant] {
      def present(value: SilhouetteModels.AutoAttendant): String = {
        s"${value.extension} - ${value.name}"
      }
    }

  implicit val callGroupPresenter = new Presenter[SilhouetteModels.CallGroup] {
    def present(value: SilhouetteModels.CallGroup): String = {
      s"${value.extension} - ${value.name}"
    }
  }

  implicit val subscriberPresenter =
    new Presenter[SilhouetteModels.Subscriber] {
      def present(value: SilhouetteModels.Subscriber): String = {
        s"${value.extension} - ${value.firstName.map(_ + " ").getOrElse("")}${value.lastName}"
      }
    }

  def buildSilhouetteUserPresenter(implicit
      aaPresenter: Presenter[SilhouetteModels.AutoAttendant],
      cgPresenter: Presenter[SilhouetteModels.CallGroup],
      subPresenter: Presenter[SilhouetteModels.Subscriber]
  ) =
    new Presenter[SilhouetteModels.SilhouetteLocation] {
      def present(value: SilhouetteModels.SilhouetteLocation): String = {
        value match {
          case aa: SilhouetteModels.AutoAttendant =>
            Presenter[SilhouetteModels.AutoAttendant](
              SilhouettePresenters.autoAttendantPresenter
            ).present(aa)
          case cg: SilhouetteModels.CallGroup =>
            Presenter[SilhouetteModels.CallGroup](
              SilhouettePresenters.callGroupPresenter
            ).present(cg)
          case sub: SilhouetteModels.Subscriber =>
            Presenter[SilhouetteModels.Subscriber](
              SilhouettePresenters.subscriberPresenter
            ).present(sub)
        }
      }
    }

  implicit val silhouetteUserPresenter = buildSilhouetteUserPresenter

}
