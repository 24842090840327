package com.sludg.util.json

import java.time.{LocalDate, LocalTime}

import com.sludg.util.models.{CallModels, ReportModels}
import com.sludg.util.models.ReportModels._
import com.sludg.util.models.ReportModels.Filter._
import play.api.libs.functional.syntax.{unlift, _}
import play.api.libs.json.{JsString, _}

object FiltersJsonDeserializers {

  import com.sludg.util.JavaTimeFormats._
  import CallJsonDeserializers._
  import com.sludg.util.MiscFormats.cronFormat

  implicit val timeFilterWrites: Writes[TimeFilter] = (
    (JsPath \ "startTime").write[LocalTime] and
      (JsPath \ "endTime").write[LocalTime]
  )(unlift(TimeFilter.unapply))

  implicit val timeFilterReads: Reads[TimeFilter] = (
    (JsPath \ "startTime").read[LocalTime] and
      (JsPath \ "endTime").read[LocalTime]
  )(TimeFilter.apply _)

  implicit val dateFilterWrites: Writes[DateFilter] = (
    (JsPath \ "startDate").write[LocalDate] and
      (JsPath \ "endDate").write[LocalDate]
  )(unlift(DateFilter.unapply))

  implicit val dateFilterReads: Reads[DateFilter] = (
    (JsPath \ "startDate").read[LocalDate] and
      (JsPath \ "endDate").read[LocalDate]
  )(DateFilter.apply _)

  implicit val callFilterReads: Reads[ClassOfServiceFilter] = (__ \ "cos")
    .read[List[CallModels.ClassOfService]]
    .map(e => ClassOfServiceFilter(e))
  implicit val callFilterWrites: Writes[ClassOfServiceFilter] =
    (__ \ "cos").write[List[CallModels.ClassOfService]].contramap(e => e.cos)

  implicit val answerFilterReads: Reads[AnswerFilter] =
    (__ \ "answer").read[Boolean].map(e => AnswerFilter(e))
  implicit val answerFilterWrites: Writes[AnswerFilter] =
    (__ \ "answer").write[Boolean].contramap(e => e.answer)

  implicit val extensionFilterReads: Reads[ExtensionFilter] =
    (__ \ "extensions").read[List[String]].map(e => ExtensionFilter(e))
  implicit val extensionFilterWrites: Writes[ExtensionFilter] =
    (__ \ "extensions").write[List[String]].contramap(a => a.extensions)

  implicit val autoAttendantFilterReads: Reads[AutoAttendantFilter] =
    (__ \ "extensions").read[List[String]].map(e => AutoAttendantFilter(e))
  implicit val autoAttendantFilterWrites: Writes[AutoAttendantFilter] =
    (__ \ "extensions").write[List[String]].contramap(a => a.extensions)

  implicit val callGroupFilterReads: Reads[CallGroupFilter] =
    (__ \ "extensions").read[List[String]].map(e => CallGroupFilter(e))
  implicit val callGroupFilterWrites: Writes[CallGroupFilter] =
    (__ \ "extensions").write[List[String]].contramap(a => a.extensions)

  implicit val subscriberFilterReads: Reads[SubscriberFilter] =
    (__ \ "subscriberIds").read[List[Int]].map(e => SubscriberFilter(e))
  implicit val subscriberFilterWrites: Writes[SubscriberFilter] =
    (__ \ "subscriberIds").write[List[Int]].contramap(a => a.subscriberIds)

  implicit val numberCallFilterReads: Reads[DialedNumberFilter] =
    (__ \ "dialedNumberFilter")
      .read[List[String]]
      .map(e => DialedNumberFilter(e))
  implicit val numberCallFilterWrites: Writes[DialedNumberFilter] =
    (__ \ "dialedNumberFilter")
      .write[List[String]]
      .contramap(a => a.dialedNumbers)

  implicit val directionFilterReads: Reads[DirectionFilter] = (__ \ "direction")
    .read[List[CallModels.Direction]]
    .map(e => DirectionFilter(e))
  implicit val directionFilterWrites: Writes[DirectionFilter] =
    (__ \ "direction")
      .write[List[CallModels.Direction]]
      .contramap(a => a.direction)

  implicit val terminationFilterReads: Reads[TerminationFilter] =
    (__ \ "termination")
      .read[List[CallModels.TerminationPoint]]
      .map(e => TerminationFilter(e))
  implicit val terminationFilterWrites: Writes[TerminationFilter] =
    (__ \ "termination")
      .write[List[CallModels.TerminationPoint]]
      .contramap(a => a.termination)

  implicit val callingNumberFilterReads: Reads[CallingNumberFilter] =
    (__ \ "callingNumber").read[List[String]].map(e => CallingNumberFilter(e))
  implicit val callingNumberFilterWrites: Writes[CallingNumberFilter] =
    (__ \ "callingNumber").write[List[String]].contramap(a => a.callingNumber)

  implicit val relativeDateFilterReads: Reads[RelativeDateFilter] =
    (__ \ "previousDaysToInclude").read[Int].map(RelativeDateFilter.apply)
  implicit val relativeDateFilterWrites: Writes[RelativeDateFilter] =
    (__ \ "previousDaysToInclude").write[Int].contramap(_.previousDaysToInclude)

  implicit val filterReads: Reads[Filter] =
    (JsPath \ "type").read[String].flatMap {
      case "NumberCallFilter"     => numberCallFilterReads.map(identity)
      case "CallingNumberFilter"  => callingNumberFilterReads.map(identity)
      case "DirectionFilter"      => directionFilterReads.map(identity)
      case "AnswerFilter"         => answerFilterReads.map(identity)
      case "TerminationFilter"    => terminationFilterReads.map(identity)
      case "ClassOfServiceFilter" => callFilterReads.map(identity)
      case "DateFilter"           => dateFilterReads.map(identity)
      case "TimeFilter"           => timeFilterReads.map(identity)
      case "SubscriberFilter"     => subscriberFilterReads.map(identity)
      case "ExtensionFilter"      => extensionFilterReads.map(identity)
      case "AutoAttendantFilter"  => autoAttendantFilterReads.map(identity)
      case "CallGroupFilter"      => callGroupFilterReads.map(identity)
      case "RelativeDateFilter"   => relativeDateFilterReads.map(identity)
    }

  implicit val filterWrites: Writes[Filter] = Writes[Filter] {
    case n: DialedNumberFilter =>
      numberCallFilterWrites.writes(n).as[JsObject] + ("type" -> JsString(
        "NumberCallFilter"
      ))
    case c: CallingNumberFilter =>
      callingNumberFilterWrites.writes(c).as[JsObject] + ("type" -> JsString(
        "CallingNumberFilter"
      ))
    case d: DirectionFilter =>
      directionFilterWrites.writes(d).as[JsObject] + ("type" -> JsString(
        "DirectionFilter"
      ))
    case a: AnswerFilter =>
      answerFilterWrites.writes(a).as[JsObject] + ("type" -> JsString(
        "AnswerFilter"
      ))
    case t: TerminationFilter =>
      terminationFilterWrites.writes(t).as[JsObject] + ("type" -> JsString(
        "TerminationFilter"
      ))
    case c: ClassOfServiceFilter =>
      callFilterWrites.writes(c).as[JsObject] + ("type" -> JsString(
        "ClassOfServiceFilter"
      ))
    case d: DateFilter =>
      dateFilterWrites.writes(d).as[JsObject] + ("type" -> JsString(
        "DateFilter"
      ))
    case t: TimeFilter =>
      timeFilterWrites.writes(t).as[JsObject] + ("type" -> JsString(
        "TimeFilter"
      ))
    case s: SubscriberFilter =>
      subscriberFilterWrites.writes(s).as[JsObject] + ("type" -> JsString(
        "SubscriberFilter"
      ))
    case e: ExtensionFilter =>
      extensionFilterWrites.writes(e).as[JsObject] + ("type" -> JsString(
        "ExtensionFilter"
      ))
    case e: AutoAttendantFilter =>
      autoAttendantFilterWrites.writes(e).as[JsObject] + ("type" -> JsString(
        "AutoAttendantFilter"
      ))
    case e: CallGroupFilter =>
      callGroupFilterWrites.writes(e).as[JsObject] + ("type" -> JsString(
        "CallGroupFilter"
      ))
    case r: RelativeDateFilter =>
      relativeDateFilterWrites.writes(r).as[JsObject] + ("type" -> JsString(
        "RelativeDateFilter"
      ))
  }

  implicit val reportFormat: Format[ReportModels.Report] =
    Json.format[ReportModels.Report]
  implicit val reportCreationRequestFormat
      : Format[ReportModels.ReportCreationRequest] =
    Json.format[ReportModels.ReportCreationRequest]

  val reportScheduleReads: Reads[ReportModels.ReportSchedule] =
    Json.reads[ReportModels.ReportSchedule]
  val reportScheduleWrites: Writes[ReportModels.ReportSchedule] =
    Json.writes[ReportModels.ReportSchedule]
  implicit val reportScheduleFormat: Format[ReportModels.ReportSchedule] =
    Format(reportScheduleReads, reportScheduleWrites)

  implicit val reportWithFiltersFormat: Format[ReportWithFilters] =
    Json.format[ReportWithFilters]

}
