package com.sludg.util.models

import com.sludg.util.models.DashboardComponentModels.ComponentType.{
  UserPresence,
  UserStats
}
import com.sludg.util.models.ReportModels.Filter
import com.sludg.util.models.SilhouetteModels.{
  CallGroup,
  CallGroupMemberData,
  Subscriber
}

import cats.Eq
import cats.Show

object DashboardComponentModels {

  /** @param id                 Unique id
    * @param x                  X position in grid
    * @param y                  Y position in grid
    * @param height             Height of component
    * @param width              Width of component
    * @param subscribedTenantId Tenant Id of data subscription
    * @param title              Title of component
    * @param filters            Filters
    * @param componentConfig    Component config
    * @param eventSubscription  Event subscription
    * @param componentType      Component type
    * @param dashboardId        Id of dashboard
    */
  case class DashboardComponent(
      id: Int,
      x: Int,
      y: Int,
      height: Int,
      width: Int,
      subscribedTenantId: Int,
      title: Option[String],
      filters: List[Filter],
      componentConfig: Option[String],
      eventSubscription: Option[String],
      componentType: ComponentType,
      dashboardId: Int
  )

  object DashboardComponent {
    implicit val eq = Eq.fromUniversalEquals[DashboardComponent]
    implicit val show = Show.fromToString[DashboardComponent]
  }

  case class CallGroupMember(
      subscriber: Subscriber,
      callGroupMemberData: CallGroupMemberData
  )

  object CallGroupMember {
    implicit val eq = Eq.fromUniversalEquals[CallGroupMember]
    implicit val show = Show.fromToString[CallGroupMember]
  }

  case class CallGroupComponentData(
      callGroup: CallGroup,
      callGroupMembers: List[CallGroupMember]
  )

  object CallGroupComponentData {
    implicit val eq = Eq.fromUniversalEquals[CallGroupComponentData]
    implicit val show = Show.fromToString[CallGroupComponentData]
  }

  sealed trait ComponentType

  object ComponentType {
    implicit val eq = Eq.fromUniversalEquals[ComponentType]
    implicit val show = Show.fromToString[ComponentType]

    case object UserPresence extends ComponentType

    case object IncomingStats extends ComponentType

    case object OutgoingStats extends ComponentType

    case object CallGroup extends ComponentType

    case object UserStats extends ComponentType

    val stringToObjection: Map[String, ComponentType] = Map(
      "UserPresence" -> UserPresence,
      "IncomingStats" -> IncomingStats,
      "OutgoingStats" -> OutgoingStats,
      "CallGroup" -> CallGroup,
      "UserStats" -> UserStats
    )

    val objectionToString: Map[ComponentType, String] =
      stringToObjection.map(_.swap)
  }
}
