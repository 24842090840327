package com.sludg.util.models

import com.sludg.util.models.SilhouetteModels.Subscriber
import cats.Eq
import cats.Show

object RoleModels {
  case class AssignedRoles(subscriber: Subscriber, roles: List[Role])

  object AssignedRoles {
    implicit val eq = Eq.fromUniversalEquals[AssignedRoles]
    implicit val show = Show.fromToString[AssignedRoles]
  }

  sealed trait Role

  object Role {
    implicit val eq = Eq.fromUniversalEquals[Role]
    implicit val show = Show.fromToString[Role]

    case object DashboardAdmin extends Role

    case object AnalyticsAdmin extends Role

    val strToObj: Map[String, Role] = Map(
      "Dashboard_Admin" -> DashboardAdmin,
      "Analytics_Admin" -> AnalyticsAdmin
    )
  }
}
