package com.sludg.util.json

import com.sludg.util.models.RoleModels.{AssignedRoles, Role}
import play.api.libs.json._
import com.sludg.util.models.SilhouetteModels.CallGroupType
import SilhouetteDeserializers._
import com.sludg.util.JsonUtils

object RoleJsonDeserializers {
  implicit val roleFormat: Format[Role] = JsonUtils.formatViaMap(Role.strToObj)
  implicit val subscriberRoleFormat: Format[AssignedRoles] =
    Json.format[AssignedRoles]
}
