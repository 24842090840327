package com.sludg.util.json

import com.sludg.util.models.CallModels.{CDR, CallStats}
import com.sludg.util.models.CallModels
import play.api.libs.json._

object CallJsonDeserializers {

  import com.sludg.util.JsonUtils._
  import com.sludg.util.JavaTimeFormats._

  import GroupingJsonDeserializers.ringStatsFormat
  import GroupingJsonDeserializers.talkStatsFormat

  implicit val directionFormat: Format[CallModels.Direction] = formatViaMap(
    CallModels.Direction.strToObj
  )
  implicit val classOfServiceFormat: Format[CallModels.ClassOfService] =
    formatViaMap(CallModels.ClassOfService.strToObj)
  implicit val terminationPointFormat: Format[CallModels.TerminationPoint] =
    formatViaMap(CallModels.TerminationPoint.strToObj)

  implicit val cdrReads: Reads[CDR] = Json.reads[CDR]
  implicit val cdrWrites: Writes[CDR] = Json.writes[CDR]

  implicit val callStatsWrites: Writes[CallStats] = Json.writes[CallStats]
  implicit val callStatsReads: Reads[CallStats] = Json.reads[CallStats]
}
