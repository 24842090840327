package com.sludg.util.models

import cats.kernel.Eq
import cats.Show

/**
  * @author dpoliakas
  *         Date: 19/11/2018
  *         Time: 13:28
  */
object SilhouetteModels {
  trait SilhouetteLocation {
    val extension: String
  }

  object SilhouetteLocation {
    implicit val eq = Eq.fromUniversalEquals[SilhouetteLocation]
    implicit val show = Show.fromToString[SilhouetteLocation]
  }

  case class CallResponse(callId: String)

  object CallResponse {
    implicit val eq = Eq.fromUniversalEquals[CallResponse]
    implicit val show = Show.fromToString[CallResponse]
  }

  case class Tenant(id: Int, name: String, uri: String)

  object Tenant {
    implicit val eq = Eq.fromUniversalEquals[Tenant]
    implicit val show = Show.fromToString[Tenant]
  }

  case class Subscriber(
      tenantId: Int,
      extension: String,
      subscriberId: Int,
      firstName: Option[String],
      lastName: String,
      email: String,
      admin: Boolean
  ) extends SilhouetteLocation

  object Subscriber {
    implicit val eq = Eq.fromUniversalEquals[Subscriber]
    implicit val show = Show.fromToString[Subscriber]
  }

  case class CallGroup(
      id: Int,
      groupType: CallGroupType,
      extension: String,
      name: String
  ) extends SilhouetteLocation

  object CallGroup {
    implicit val eq = Eq.fromUniversalEquals[CallGroup]
    implicit val show = Show.fromToString[CallGroup]
  }

  case class CallGroupMemberData(loggedIn: Boolean, twinningEnabled: Boolean)

  object CallGroupMemberData {
    implicit val eq = Eq.fromUniversalEquals[CallGroupMemberData]
    implicit val show = Show.fromToString[CallGroupMemberData]
  }

  case class AutoAttendant(id: Int, extension: String, name: String)
      extends SilhouetteLocation

  object AutoAttendant {
    implicit val eq = Eq.fromUniversalEquals[AutoAttendant]
    implicit val show = Show.fromToString[AutoAttendant]
  }

  case class Permission(
      subscriberId: Int,
      permissionName: String,
      systemName: Option[String]
  )

  object Permission {
    implicit val eq = Eq.fromUniversalEquals[Permission]
    implicit val show = Show.fromToString[Permission]
  }

  case class PermissionRequest(subscriberId: Int, permissions: List[Permission])

  object PermissionRequest {
    implicit val eq = Eq.fromUniversalEquals[PermissionRequest]
    implicit val show = Show.fromToString[PermissionRequest]
  }

  case class PermissionObj(
      permissionName: String,
      tenantId: Option[Int],
      subscriberId: Option[Int]
  )

  object PermissionObj {
    implicit val eq = Eq.fromUniversalEquals[PermissionObj]
    implicit val show = Show.fromToString[PermissionObj]
  }

  case class CarrierObject(permissionName: String, permissionType: String)

  object CarrierObject {
    implicit val eq = Eq.fromUniversalEquals[CarrierObject]
    implicit val show = Show.fromToString[CarrierObject]
  }

  case class UserPhone(
      identifier: String,
      phoneId: Int,
      phoneModelName: String,
      userLabel: String,
      defaultC2CPhone: Boolean,
      defaultIntercomPhone: Boolean
  )

  object UserPhone {
    implicit val eq = Eq.fromUniversalEquals[UserPhone]
    implicit val show = Show.fromToString[UserPhone]
  }

  case class DIDNumber(
      didId: Int,
      phoneNumber: String,
      assigned: Boolean,
      businessHoursFromTenantDefault: Boolean,
      incomingName: Option[String],
      closedHoursExtension: Option[String],
      lunchHoursExtension: Option[String],
      openHoursExtension: Option[String],
      specialHoursExtension: Option[String],
      businessHoursProfile: BusinessHoursProfile,
      emergencyOverride: EmergencyOverride
  )

  object DIDNumber {
    implicit val eq = Eq.fromUniversalEquals[DIDNumber]
    implicit val show = Show.fromToString[DIDNumber]
  }

  case class BusinessHoursProfile(
      businessHoursProfileId: Int,
      businessHoursProfileName: Option[String]
  )

  object BusinessHoursProfile {
    implicit val eq = Eq.fromUniversalEquals[BusinessHoursProfile]
    implicit val show = Show.fromToString[BusinessHoursProfile]
  }

  case class EmergencyOverride(
      active: Boolean,
      name: Option[String],
      number: Option[String]
  )

  object EmergencyOverride {
    implicit val eq = Eq.fromUniversalEquals[EmergencyOverride]
    implicit val show = Show.fromToString[EmergencyOverride]
  }

  case class HourlyExtensions(
      closedHoursExtension: Option[String],
      lunchHoursExtension: Option[String],
      openHoursExtension: Option[String],
      specialHoursExtension: Option[String]
  )

  object HourlyExtensions {
    implicit val eq = Eq.fromUniversalEquals[HourlyExtensions]
    implicit val show = Show.fromToString[HourlyExtensions]
  }

  sealed trait CallGroupType

  object CallGroupType {

    implicit val eq = Eq.fromUniversalEquals[CallGroupType]
    implicit val show = Show.fromToString[CallGroupType]

    case object BroadcastGroup extends CallGroupType

    case object HuntGroup extends CallGroupType

    case object RolloverGroup extends CallGroupType

    case object PickupGroup extends CallGroupType

    case object PageGroup extends CallGroupType

    val strToObj: Map[String, CallGroupType] = Map(
      "BroadcastGroup" -> BroadcastGroup,
      "HuntGroup" -> HuntGroup,
      "RolloverGroup" -> RolloverGroup,
      "PickupGroup" -> PickupGroup,
      "PageGroup" -> PageGroup
    )
  }

}
