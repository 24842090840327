package com.sludg.util.models

import java.time.{LocalDate, LocalTime}

import com.sludg.util.models.GroupingModels.CategoryData
import com.sludg.util.models.ReportModels.Sort
import cron4s.expr.CronExpr
import cats.Show
import cats.Eq

object ReportModels {

  case class Report(id: Int, tenantId: Int, name: String, columnIds: List[Int])

  object Report {
    implicit val eq = Eq.fromUniversalEquals[Report]
    implicit val show = Show.fromToString[Report]
  }

  case class ReportCreationRequest(reportName: String, filters: List[Filter])

  object ReportCreationRequest {
    implicit val eq = Eq.fromUniversalEquals[ReportCreationRequest]
    implicit val show = Show.fromToString[ReportCreationRequest]
  }

  case class ReportWithFilters(
      reportId: Int,
      tenantId: Int,
      reportName: String,
      filters: List[Filter]
  )

  object ReportWithFilters {
    implicit val eq = Eq.fromUniversalEquals[ReportWithFilters]
    implicit val show = Show.fromToString[ReportWithFilters]
  }

  case class ReportSchedule(
      id: Int,
      reportId: Int,
      tenantId: Int,
      schedule: CronExpr,
      emails: List[String],
      callItemisation: Boolean
  )

  object ReportSchedule {
    implicit val eq = Eq.fromUniversalEquals[ReportSchedule]
    implicit val show = Show.fromToString[ReportSchedule]
  }

  sealed trait Filter

  object Filter {
    implicit val eq = Eq.fromUniversalEquals[Filter]
    implicit val show = Show.fromToString[Filter]

    case class DialedNumberFilter(dialedNumbers: List[String]) extends Filter

    case class CallingNumberFilter(callingNumber: List[String]) extends Filter

    case class DirectionFilter(direction: List[CallModels.Direction])
        extends Filter

    case class AnswerFilter(answer: Boolean) extends Filter

    case class TerminationFilter(termination: List[CallModels.TerminationPoint])
        extends Filter

    case class ClassOfServiceFilter(cos: List[CallModels.ClassOfService])
        extends Filter

    case class DateFilter(startDate: LocalDate, endDate: LocalDate)
        extends Filter

    case class TimeFilter(startTime: LocalTime, endTime: LocalTime)
        extends Filter

    case class SubscriberFilter(subscriberIds: List[Int]) extends Filter

    case class ExtensionFilter(extensions: List[String]) extends Filter

    case class AutoAttendantFilter(extensions: List[String]) extends Filter

    case class CallGroupFilter(extensions: List[String]) extends Filter

    case class RelativeDateFilter(previousDaysToInclude: Int) extends Filter

  }

  sealed trait Sort

  object Sort {
    implicit val eq = Eq.fromUniversalEquals[Sort]
    implicit val show = Show.fromToString[Sort]

    case object Ascending extends Sort

    case object Neutral extends Sort

    case object Descending extends Sort

    val strToObj: Map[String, Sort] = Map(
      "Ascending" -> Ascending,
      "Neutral" -> Neutral,
      "Descending" -> Descending
    )

    val objToStr: Map[Sort, String] = strToObj.map(_.swap).toMap
  }

  sealed trait ProjectionType

  object ProjectionType {
    implicit val eq = Eq.fromUniversalEquals[ProjectionType]
    implicit val show = Show.fromToString[ProjectionType]

    case object TotalCalls extends ProjectionType

    case object MinDurations extends ProjectionType

    case object MaxDurations extends ProjectionType

    case object AvgDurations extends ProjectionType

    case object TotalDurations extends ProjectionType

    case object MinTalkTime extends ProjectionType

    case object MaxTalkTime extends ProjectionType

    case object AvgTalkTime extends ProjectionType

    case object TotalTalkTime extends ProjectionType

    case object MinRingTime extends ProjectionType

    case object MaxRingTime extends ProjectionType

    case object AvgRingTime extends ProjectionType

    case object TotalRingTime extends ProjectionType

    val strToObj: Map[String, ProjectionType] = Map(
      "Total Calls" -> TotalCalls,
      "Min Durations" -> MinDurations,
      "Max Durations" -> MaxDurations,
      "Average Durations" -> AvgDurations,
      "Total Durations" -> TotalDurations,
      "Min Talk Time" -> MinTalkTime,
      "Max Talk Time" -> MaxTalkTime,
      "Avg Talk Time" -> AvgTalkTime,
      "Total Talk Time" -> TotalTalkTime,
      "Min Ring Time" -> MinRingTime,
      "Max Ring Time" -> MaxRingTime,
      "Avg Ring Time" -> AvgRingTime,
      "Total Ring Time" -> TotalRingTime
    )

    val objToStr: Map[ProjectionType, String] = strToObj.map(_.swap).toMap
  }

  case class ReportColumn(
      id: Int,
      name: String,
      sort: Sort,
      columns: List[CategoryData[_]],
      projectionType: ProjectionType,
      reportId: Int
  )

  object ReportColumn {
    implicit val eq = Eq.fromUniversalEquals[ReportColumn]
    implicit val show = Show.fromToString[ReportColumn]
  }

}
