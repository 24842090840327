package com.sludg.util

import java.time.format.DateTimeFormatter
import java.time.{LocalDate, LocalDateTime, LocalTime}

import cron4s.Cron
import play.api.libs.json._

import scala.util.Try

/**
  * @author dpoliakas
  *         Date: 2019-01-17
  *         Time: 15:30
  */
object JsonUtils {

  private[this] def reverseMap[A, B](m: Map[A, B]): Map[B, A] =
    m.map {
      case (a, b) => b -> a
    }

  def formatViaMap[A](read: Map[String, A]): Format[A] = {
    val write = reverseMap(read)
    Format(
      Reads {
        case JsString(x) =>
          read.get(x).map(x => JsSuccess(x)).getOrElse(JsError())
        case _ => JsError()
      },
      Writes((write.apply _).andThen(JsString))
    )
  }
}

object MiscFormats {

  implicit val cronWriter: Writes[cron4s.expr.CronExpr] =
    Writes.apply(c => JsString.apply(c.toString))
  implicit val cronReads: Reads[cron4s.expr.CronExpr] = Reads.apply {
    case JsString(cronString) =>
      Cron
        .parse(cronString)
        .fold(
          e => JsError(e.getMessage),
          c => JsSuccess(c)
        )
    case _ => JsError("Cron expression can only be a string")
  }
  implicit val cronFormat: Format[cron4s.expr.CronExpr] =
    Format(cronReads, cronWriter)

}

object JavaTimeFormats {

  implicit val localDateFormat = new Format[LocalDate] {
    override def reads(json: JsValue): JsResult[LocalDate] =
      json.validate[String].map(LocalDate.parse)

    override def writes(o: LocalDate): JsValue = Json.toJson(o.toString)
  }

  implicit val localTimeFormat = new Format[LocalTime] {
    override def reads(json: JsValue): JsResult[LocalTime] =
      json.validate[String].map(LocalTime.parse)

    override def writes(o: LocalTime): JsValue = Json.toJson(o.toString)
  }

  implicit val localDateTimeFormat = Format[LocalDateTime](
    implicitly[Reads[String]]
      .map(v =>
        Try(LocalDateTime.parse(v, DateTimeFormatter.ISO_LOCAL_DATE_TIME))
      )
      .filter(
        JsonValidationError(
          "The timestamp could not be parsed as a valid ISO date/time string"
        )
      )(_.isSuccess)
      .map(_.get),
    implicitly[Writes[String]]
      .contramap(_.format(DateTimeFormatter.ISO_LOCAL_DATE_TIME))
  )
}
